import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root' // Corrected syntax
})
export class TransferCommissionService {
  url: string;

  constructor(private http: HttpClient) {
    this.url = environment.baseApiUrl + 'admin/';
    if (localStorage.getItem('title')) {
      this.url = localStorage.getItem('url') + 'admin/';
    }
  }

  getAllTransferCommissionLogs() {
    return this.http.get(
      environment.production
        ? environment.baseApiUrl + 'get-commissions-transfers'
        : environment.baseApiUrlTest + 'get-commissions-transfers'
    ).pipe(
      map((response: any) => response)
    );
  }


  initializeTransferCommission(data) {
    const response = this.http.post(environment.production ? environment.baseApiUrl  + 'create-commission-transfer' : environment.baseApiUrlTest + 'create-commission-transfer', data)
  
     return response;
  }

  getBankName(data) {
    const response = this.http.post(environment.production ? environment.baseApiUrl  + 'get-bank-account-name' : environment.baseApiUrlTest + 'get-bank-account-name', data)
  
     return response;
  }
}
