import { Component, OnInit, Output, EventEmitter, Input, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { TransferCommissionService } from '../../shared/services/transferCommission.service';
import { AgentService } from '../../shared/services/agent.service';
import { BankService } from '../../shared/services/bank.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { debounceTime, switchMap, tap, finalize, distinctUntilChanged, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AutocompleteService } from '../../shared/services/autocomplete.service';
import { AdminService } from "../../shared/services/admin.service";

class FilterModel {
  transaction_ref!: number;
  agent!: number;
  name!: string;
  bank!: string;
  status!: string;
  mode_of_payment!: string;
  start_date!: string | Date;
  end_date!: string | Date;
}

@Component({
  selector: 'app-initialize-transfer-commission',
  templateUrl: './initialize-transfer-commission.component.html',
  styleUrls: ['./initialize-transfer-commission.component.css']
})
export class InitializeTransferCommissionComponent implements OnInit {

  @Input() agents: any[] = [];
  @Input() banks: any[] = [];
  @Output() closeFilter = new EventEmitter<boolean>();
  @Output() filteredTransaction = new EventEmitter<any>();
  @Output() transferDetailLogs = new EventEmitter<any>();
  @Output() onFormReset = new EventEmitter<boolean>();

  model = new FilterModel();
  display: boolean = false;
  filteredUsers: any[] = [];
  isLoading: boolean = false;
  searching: boolean = false;
  searchFailed: boolean = false;
  results: any[] = [];
  details: any;
  errorMessage: string;
  transferLogs: any[] = []; 
  pagination = {};
  config: any;
  bank_account_name: string = 'No account name to display yet';

  @ViewChild('filterForm', { static: false }) form!: FormGroup;

  constructor(
    private fb: FormBuilder,
    private transferCommissionService: TransferCommissionService,
    private agentService: AgentService,
    private bankService: BankService,
    private autocompleteService: AutocompleteService,
    private adminService: AdminService,
    private cdr: ChangeDetectorRef
  ) {}

 

  ngOnInit() {
    this.initializeForm();  // Make sure form is initialized before doing anything
    this.getAllAgents();
    this.getAllBanks();
    
    // // Set a default beneficiary name (if necessary)
    // this.form.patchValue({ beneficiary_name: 'dancing' });
  
    // // Subscribe to account_number changes
    // const accountNumberControl = this.form.get('account_number');
    // console.log('This is number:', accountNumberControl);
    // if (accountNumberControl) {
    //   accountNumberControl.valueChanges.pipe(
    //     debounceTime(300),
    //     distinctUntilChanged()
    //   ).subscribe(value => {
    //     console.log('This is value:', value);
    //     if (value && value.length === 10) {
    //       this.getBankAccountName();
    //     }
    //   });
    // }
     // Set a default beneficiary name (if necessary)
  this.form.patchValue({ beneficiary_name: 'dancing' });
  
  // Subscribe to account_number changes
  const accountNumberControl = this.form.get('account_number');
  console.log('This is number:', accountNumberControl);
  if (accountNumberControl) {
    accountNumberControl.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe(value => {
      console.log('This is value:', value);
      if (value && value.length === 10) {
        this.getBankAccountName();
      }
    });
  }

    
  }
  
     

  initializeForm() {
    this.form = this.fb.group({
      account_bank: ['', Validators.required],
      account_number: ['', Validators.required],
      beneficiary_name: [''],
      amount: [''],
      narration: ['']
    });
  }

  getDetails() {
    this.adminService.getDetails().subscribe(
      data => {
        this.details = data[0];
      },
      error => console.error('Error fetching details:', error)
    );
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term =>
        this.autocompleteService.search(term).pipe(
          tap(results => {
            this.searchFailed = false;
            this.results = results;
          }),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })
        )
      ),
      tap(() => this.searching = false)
    );

  onCloseFilter() {
    this.closeFilter.emit(false);
    this.display = !this.display;
  }

  onToggle() {
    this.display = !this.display;
  }

  onFilter() {
    console.log('Filter form values:', this.form.value);
    this.transferCommissionService.initializeTransferCommission(this.form.value).subscribe(
      data => {
        this.filteredTransaction.emit(data);
      },
      error => console.error('Error in filtering:', error)
    );
    this.onToggle();
  }

  getTransferLogs() {
    this.transferCommissionService
      .getAllTransferCommissionLogs()
      .subscribe((response) => {
        this.transferLogs = response.data;
        this.config = {
          itemsPerPage: 10,
          currentPage: 1,
          totalItems: response.data.length,
        };

        console.log("this is transferLogs", this.transferLogs);

        // Emit the config and transferLogs to the parent component
      this.transferDetailLogs.emit({ transferLogs: this.transferLogs, config: this.config });
      });
  }


  
  getBankAccountName() {

    this.bank_account_name = 'Fetching account details...'

    const dataToSubmit = 
    {
      account_bank: this.form.value.account_bank,
      account_number: this.form.value.account_number
    }
    this.transferCommissionService.getBankName(dataToSubmit).subscribe((response: any) => {

      const account_details = response.data;

      console.log('this is account name', account_details)
      this.bank_account_name = account_details.account_name
      // this.form.patchValue({ beneficiary_name: account_details.account_name });
      console.log('jinted', this.form.value.beneficiary_name)
      },
    
      error => {
        this.bank_account_name = 'Error fetching bank account owner'
      });
  }
  
  

  onAccountNumberChange() {
    const value = this.form.value.account_number;
    console.log('Account Number Changed:', value);
    console.log('forms methods', this.form)
    if (value && value.length === 10) {
      this.getBankAccountName();
    }
  }

  onSubmit() {
    this.isLoading = true;
    console.log('Submitting form:', this.form.value);

    const bankName = this.banks.find((item) => item.code === this.form.value.account_bank);

    const dataToSubmit = {
      ...this.form.value,
      beneficiary_name: this.bank_account_name,
      reference: Date.now().toString(),
      // reference: Date.now().toString()+'_PMCKDU_1',
      bank_name: bankName.name,
      currency: 'NGN',
      narration: this.form.value.narration === undefined ? '' : this.form.value.narration
    };

    console.log("Data to submit:", dataToSubmit);

    this.transferCommissionService.initializeTransferCommission(dataToSubmit).subscribe(
      data => {
        this.onToggle();
        this.isLoading = false;

        // Retry mechanism for parent component
        this.getTransferLogs();
      },
      error => {
        this.errorMessage = error.error.message;
        console.error('Error submitting form:', error.error.message);
        this.isLoading = false;
      }
    );
  }


  resetFilter() {
    this.form.reset();
  }

  getAllAgents() {
    this.agentService.get().subscribe(
      data => {
        this.agents = data;
      },
      error => console.error('Error fetching agents:', error)
    );
  }

  getAllBanks() {
    this.bankService.get().subscribe(
      data => {
        this.banks = data;
  
        // Sort banks by name alphabetically
        this.banks.sort((a, b) => a.name.localeCompare(b.name));
  
        console.log('Sorted Banks data:', this.banks);
      },
      error => console.error('Error fetching banks:', error)
    );
  }
}
