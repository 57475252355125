import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { TransactionService } from '../../shared/services/transaction.service';
import {AgentService} from '../../shared/services/agent.service';
import {BankService} from '../../shared/services/bank.service';
import {FormBuilder} from '@angular/forms';
import {debounceTime, switchMap, tap, finalize, distinctUntilChanged, catchError} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {AutocompleteService} from '../../shared/services/autocomplete.service';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AdminService} from "../../shared/services/admin.service";

class FilterModel {
  transaction_ref: number;
  agent: number;
  name: string;
  bank: string;
  status: string;
  mode_of_payment: string;
  start_date;
  end_date;
}
@Component({
  selector: 'app-transaction-filter',
  templateUrl: './transaction-filter.component.html',
  styleUrls: ['./transaction-filter.component.css']
})
export class TransactionFilterComponent implements OnInit {

  @Input('agents') agents;
  @Input('banks') banks;
  @Output('closeFilter') closeFilter = new EventEmitter();
  @Output('filteredTransactions') filteredTransaction = new EventEmitter();
  @Output('onFormReset') onFormReset = new EventEmitter();

  model = new FilterModel();
  display: boolean = false;
  filteredUsers;
  isLoading
  searching = false;
  searchFailed = false;
  results: any;
  details;

  @ViewChild('filterForm', {static: false}) form;

  constructor(fb: FormBuilder, private transactionService: TransactionService,
              private agentService: AgentService, private bankService: BankService, private autocompleteService: AutocompleteService,
              private adminService: AdminService)
  {
    this.form = fb.group({
      bank: [],
      agent: [],
      transaction_ref: [],
      sender: [],
      start_date: [],
      end_date: [],
      payment_method: [],
      status: []
    });

  }
  getDetails() {
    this.adminService.getDetails().subscribe(
      data => {
        this.details = data[0];
      });
  }
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term =>
        this.autocompleteService.search(term)
          .pipe(
          tap(results => {this.searchFailed = false; this.results = results; }),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          }))
      ),
      tap(() => this.searching = false)
    )

  ngOnInit() {
    this.getAllAgents();
    this.getAllBanks();
  }

  onCloseFilter() {
    this.closeFilter.emit(false);
    this.display = !this.display;
  }
  onToggle() {
    this.display = !this.display;
  }

  onFilter() {
    console.log('this is thing', this.form.value)
    this.transactionService.filter(this.form.value).subscribe(
      data => {
        this.filteredTransaction.emit(data);
      }
    );

    this.onToggle()
  }
  resetFilter() {
    this.form.reset();
    this.onFormReset.emit(true);
  }
  getAllAgents() {
    this.agentService.get().subscribe(
      data => this.agents = data
    );
  }

  getAllBanks() {
    this.bankService.get().subscribe(
      data => this.banks = data
    );
  }

}
