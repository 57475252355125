import { Component, OnInit } from '@angular/core';
import {TransactionService} from '../shared/services/transaction.service';
import {AdminService} from '../shared/services/admin.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit {
  data = {};
  details;
  balance;
  partnerCommissionInPayInCurrency;
  partnerCommissionInSettlement
  constructor(private transactionService: TransactionService, private adminService: AdminService) {
  }

  ngOnInit() {
    this.getDetails();
    this.transactionService.statistics().subscribe(
      data => {
        this.data = data;
      }
    );
    // this.transactionService.getDetails().subscribe(
    //   data => {
    //     this.data = data;
    //   }
    // );
  }

  getDetails() {
    // this.transactionService.getDetails().subscribe(
    //   data => {
    //     this.details = data[0];
    //   }
    // );
    this.adminService.getDetails().subscribe(
      data => {
        this.details = data[0];
        // console.log('this is details', data[0].agent.slug)
       this.transactionService.getBalance(data[0].agent.slug).subscribe(
      data => {
        this.balance = data.balance;
        this.partnerCommissionInPayInCurrency = data.partnerCommissionInPayInCurrency;
        this.partnerCommissionInSettlement = data.partnerCommissionInSettlement;
      }
    );
      });

  }


}
