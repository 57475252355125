import { Component, OnInit } from "@angular/core";
import { TransferCommissionService } from "../shared/services/transferCommission.service";
import { ExcelService } from "../shared/services/excel.service";

@Component({
  selector: "app-transfer-commission",
  templateUrl: "./transfer-commission.component.html",
  styleUrls: ["./transfer-commission.component.css"],
})
export class TransferCommissionComponent implements OnInit {
  transferLogs: any[] = []; // Store logs here
  errorMessage: string = "";
  pagination = {};
  config: any;

  constructor(
    private transferCommissionService: TransferCommissionService,
    private excelService: ExcelService
  ) {}

  ngOnInit() {
    this.getTransferLogs();
  }

  // getTransferLogs() {
  //   this.transferCommissionService.getAllTransferCommissionLogs().subscribe({
  //     next: (data) => {
  //       this.transferLogs = data;

  //       console.log('this is data', data)
  //     },
  //     error: (error) => {
  //       this.errorMessage = 'Error fetching transfer logs';
  //       console.error('Error:', error);
  //     }
  //   });
  // }


  handleTransferDetailLogs(event: { transferLogs: any[], config: any }) {
    console.log('Transfer Logs:', event.transferLogs);

    this.transferLogs = event.transferLogs;
    this.config = event.config;
    console.log('Config:', event.config);
    // Handle the received transferLogs and config here
  }

  getTransferLogs() {
    this.transferCommissionService
      .getAllTransferCommissionLogs()
      .subscribe((response) => {
        this.transferLogs = response.data;
        this.config = {
          itemsPerPage: 10,
          currentPage: 1,
          totalItems: response.data.length,
        };

        console.log("this is transferLogs", this.transferLogs);
      });
  }

  exportReports() {
    const reports = [];

    this.transferLogs.forEach(
      ({
        
account_bank,
account_number,
amount,
beneficiary_name,
created_at,
currency,
message,
narration,
reference,
status,
transaction_id,
updated_at,
      }) =>
        reports.push({
          account_bank,
          account_number,
          amount,
          beneficiary_name,
          created_at,
          currency,
          message,
          narration,
          reference,
          status,
          transaction_id,
          updated_at,
        })
    );

    this.excelService.exportAsExcelFile(reports, "icashremit transfer commission report");
  }

  pageChanged(event) {
    this.config.currentPage = event;
  }
}
