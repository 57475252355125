import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import {AgentModule} from '../agent/agent.module';
import {RouterModule} from '@angular/router';
import { InitializeTransferCommissionComponent } from './initializeTransferCommission/initialize-transfer-commission.component';
import { FormsModule } from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AngularDraggableModule} from 'angular2-draggable';

@NgModule({
  declarations: [ InitializeTransferCommissionComponent],
  imports: [
    CommonModule, SharedModule, AgentModule, RouterModule, FormsModule, NgbModule, AngularDraggableModule
  ],
  exports: [InitializeTransferCommissionComponent]
})
export class TransferCommissionModule { }
